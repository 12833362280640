<div ngModelGroup="product_shipping_setting" class="bg-white rounded-lg border px-4 py-6">
  <h3 class="mb-6 font-semibold text-gray-900">
    {{ managementPrefix + "shipment-setting" | translate | titlecase }}
  </h3>

  <div class="mt-4 flex flex-col gap-4">
    <label class="text-gray-700 text-sm">
      {{ managementPrefix + descriptionPrefix + "shipment-setting" | translate | sentenceCase }}
    </label>

    <!-- Address -->
    <div *ngIf="!isLoading && isAllowMultipleAddress" class="flex flex-col gap-1">
      <label class="text-sm font-medium" for="">{{ managementPrefix + "shop-address" | translate }}</label>
      <p-dropdown
        [readonly]="isViewMode"
        [placeholder]="!isViewMode ? (managementPrefix + 'select-shop-address' | translate) : ' '"
        [options]="addressList"
        styleClass="w-full"
        name="branch_id"
        optionLabel="name"
        optionValue="id"
        [(ngModel)]="productForm.branch_id"
      ></p-dropdown>
    </div>

    <!-- <div class="flex items-center gap-2">
      <p-inputSwitch
        class="flex items-center"
        name="is_sale_direct"
        [(ngModel)]="productForm.is_sale_direct"
      ></p-inputSwitch>
      <span class="text-sm font-medium text-gray-700">{{
        managementPrefix + descriptionPrefix + "sale-direct" | translate
      }}</span>
    </div> -->

    <!--  WEIGHT-->
    <div class="grid grid-cols-3 gap-x-4">
      <div class="flex flex-col gap-2">
        <label class="font-medium text-sm" for="product_weight"
          >{{ managementPrefix + "weight" | translate | sentenceCase }}
          <span class="text-orange-dark-500 {{ isViewMode ? 'hidden' : '' }}">*</span></label
        >
        <div class="p-inputgroup">
          <p-inputNumber
            [min]="1"
            [(ngModel)]="productForm.parcel.weight"
            class="h-10 !p-0 !rounded-tl-lg !rounded-bl-lg border-none"
            id="product_weight"
            type="number"
            name="weight"
            [minFractionDigits]="2"
            [required]="true"
            [placeholder]="managementPrefix + 'enter-weight' | translate | sentenceCase"
            [readonly]="isViewMode"
          >
          </p-inputNumber>
          <div class="p-inputgroup-addon h-10 !bg-white text-sm">gr</div>
        </div>
        <small class="text-gray-700 h-4">{{
          managementPrefix + descriptionPrefix + "weight-note" | translate | sentenceCase
        }}</small>
      </div>
    </div>
    <!--  SIZE-->
    <div class="flex flex-col gap-2">
      <label class="text-sm font-medium"
        >{{ managementPrefix + "size" | translate | sentenceCase }}
        <span class="text-error-500 {{ isViewMode ? 'hidden' : '' }}">*</span></label
      >
      <div class="flex gap-4">
        <!-- WIDTH-->
        <div class="p-inputgroup">
          <p-inputNumber
            [required]="true"
            [min]="1"
            [(ngModel)]="productForm.parcel.width"
            class="h-10 p-0 flex-1 !rounded-tl-lg !rounded-bl-lg border-none"
            type="number"
            name="width"
            [minFractionDigits]="2"
            [placeholder]="managementPrefix + 'enter-width' | translate | sentenceCase"
            [readonly]="isViewMode"
          >
          </p-inputNumber>
          <div class="p-inputgroup-addon h-10 !bg-white text-sm">cm</div>
        </div>

        <!-- LENGTH-->
        <div class="p-inputgroup">
          <p-inputNumber
            [required]="true"
            [min]="1"
            [(ngModel)]="productForm.parcel.length"
            class="h-10 flex-1 !rounded-tl-lg !rounded-bl-lg !border-none !p-0"
            type="number"
            name="length"
            [minFractionDigits]="2"
            [placeholder]="managementPrefix + 'enter-length' | translate | sentenceCase"
            [readonly]="isViewMode"
          >
          </p-inputNumber>
          <div class="p-inputgroup-addon h-10 !bg-white text-sm">cm</div>
        </div>

        <!-- HEIGHT-->
        <div class="p-inputgroup">
          <p-inputNumber
            [required]="true"
            [min]="1"
            [(ngModel)]="productForm.parcel.height"
            class="h-10 flex-1 !rounded-tl-lg !rounded-bl-lg !border-none !p-0"
            type="number"
            name="height"
            [minFractionDigits]="2"
            [placeholder]="managementPrefix + 'enter-height' | translate | sentenceCase"
            [readonly]="isViewMode"
          >
          </p-inputNumber>
          <div class="p-inputgroup-addon h-10 !bg-white text-sm">cm</div>
        </div>
      </div>
      <small class="text-gray-700 h-4">{{
        managementPrefix + descriptionPrefix + "size-note" | translate | sentenceCase
      }}</small>
    </div>
  </div>
</div>

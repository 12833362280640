import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  constructor(private httpClient: HttpClient) {}

  checkMultipleAddress(shopCode: string) {
    return this.httpClient.get<{ data: boolean }>(`${environment.BE_URL}shop/shops/allowed-shop-addresses/${shopCode}`);
  }
}
